<template>
  <div class="flex h-full w-full rounded-4 z-[12] overflow-hidden no-scrollbar">
    <div class="pb-4 h-full mx-4 xl:mx-4.5">
      <div
        v-if="outline"
        class="w-9 bg-primary flex flex-col items-center justify-start gap-3 h-full rounded-full py-4 overflow-y-auto no-scrollbar xl:w-10">
        <div
          v-for="item in outline"
          :key="item.id"
          class="flex items-center justify-center"
          :class="{
            'cursor-pointer': !item.locked,
            'cursor-not-allowed': item.locked,
          }"
          @click="!item.locked && $emit('click:outline', item)">
          <div
            class="h-7 w-7 xl:h-7.5 xl:w-7.5 pt-1 rounded-full flex items-center justify-center bg-primary-500"
            :class="{
              '!bg-primary': item.locked,
              '!bg-white': currentOutline === item.id,
              '!bg-primary-700': item.finished,
              'border-2 border-white': item.current,
            }">
            <p
              class="font-simplerBold text-md text-white"
              :class="{
                '!text-primary-500': item.locked,
                '!text-primary': currentOutline === item.id,
              }">
              {{ item.title }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col pt-5 overflow-x-hidden no-scrollbar w-[200px]">
      <div class="am-p-b-4">
        {{ title }}
      </div>
      <div class="flex flex-col no-scrollbar overflow-y-auto">
        <div v-for="(element, eIndex) in sections" :key="eIndex">
          <div
            class="h-10 xl:h-11 flex items-center"
            :class="{ 'cursor-pointer': eIndex <= lastSectionIndex }"
            @click="$emit('click:navigation', element.id, eIndex)">
            <StatusIcon
              :progress="getProgress(eIndex)"
              :show-progress="true"
              :start="currentSectionIndex === 0"
              :end="eIndex === sections.length - 1"
              :line-empty="currentSectionIndex < eIndex"/>
            <span
              :title="element.title"
              class="truncate ps-8"
              :class="{
                'font-simplerBold': currentSectionIndex >= eIndex,
              }">
              {{ element.title }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';
import { computed } from 'vue';

interface IProps {
  title: string;
  sections: { id: string; title: string }[];
  outline: {
    id: string;
    title: string;
    locked: boolean;
    finished: boolean;
    current: boolean;
    child?: boolean;
  }[];
  currentOutline: string;
  currentSection: string;
  lastSectionIndex: number;
}

const props = withDefaults(defineProps<IProps>(), {
  title: 'lesson 111',
  outline: () => [
    { id: '', title: '', locked: false, finished: false, current: false },
  ],
  sections: () => [{ id: '', title: '' }],
  currentSection: '',
  currentOutline: '',
  lastSectionIndex: 0,
});

defineEmits(['close', 'click:outline', 'click:navigation']);

const currentSectionIndex = computed(() => {
  let index = props.sections.findIndex(
    section => section.id === props.currentSection,
  );
  return index !== -1 ? index : 0;
});

const getProgress = index => {
  return index < currentSectionIndex.value
    ? 100
    : index === currentSectionIndex.value
      ? 50
      : 0;
};
</script>
