<template>
  <div
    v-if="panels.length"
    ref="targetEl"
    :dir="rtl ? 'rtl' : 'ltr'"
    class="fixed top-12 flex z-40 h-full w-105 transition-transform shadow-lg border-l border-sky-blue-400 drawer-left-temp"
    :class="rtl ? '-translate-x-full left-0' : 'translate-x-full right-0'"
    tabindex="-1"
    aria-labelledby="drawer-left-label">
    <div class="overflow-y-auto h-full w-full p-0.5 bg-secondary-75">
      <PanelContent />
    </div>
    <div
      class="flex flex-col gap-3 top-25 items-center z-41 h-fit absolute bg-transparent -start-[42px]"
      :class="{ 'scale-x-[-1]': !rtl }">
      <button
        v-for="(panel, index) in panels"
        :key="index"
        class="panel-toggle"
        :class="panel.classes"
        @click="togglePanel(index)">
        <LucideIcon :name="panel.icon" :stroke-width="1" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Drawer } from 'flowbite';
import type { DrawerOptions } from 'flowbite';
import { ref, onMounted, h, computed, watchEffect } from 'vue';
import LucideIcon from '@/components/LucideIcon/LucideIcon.vue';
import { useArrayMap } from '@vueuse/core';
import { useReveal } from '@/apps/slideshow/useReveal';
import { renderBlock } from '@/apps/slideshow/useBlockMapper';
import type { BuildingBlock } from '@/apps/slideshow/blocks';

const drawer = ref();
const panelIndex = ref();
const targetEl = ref<HTMLElement | null>(null);

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['open']);

const { slideshow, rtl } = useReveal();
const options: DrawerOptions = {
  placement: rtl.value ? 'left' : 'right',
  backdrop: false,
  bodyScrolling: true,
};

let buttonClasses = {
  active:
    'border-sky-blue-400 border-l-sky-blue-100 hover:border-l-primary-300',
  default: 'bg-white border-l-sky-blue-400',
};
const panels = computed(() =>
  (slideshow.value?.panels ?? []).map((data, i) => {
    let state = panelIndex.value === i ? 'active' : 'default';
    data.classes = computed(() => {
      return buttonClasses[state];
    });
    return data;
  }),
);

watchEffect(() => {
  if (props.show) {
    drawer.value?.show();
    emit('open');
  } else {
    drawer.value?.hide();
  }
});

onMounted(() => {
  if (targetEl.value) {
    drawer.value = new Drawer(targetEl.value as HTMLElement, options);
  }
});

const togglePanel = index => {
  if (panelIndex.value === index && !drawer.value.isHidden()) {
    return drawer.value.hide();
  }

  panelIndex.value = index;
  if (drawer.value.isHidden()) {
    drawer.value.show();
    emit('open');
  }
};

const PanelContent = () => {
  const blocks = useArrayMap(
    panels.value[panelIndex.value]?.blocks ?? [],
    (block: BuildingBlock) => {
      if (block.name === 'embed' && block?.settings?.proxy) {
        let proxyUri = `${import.meta.env.VITE_API_BASE}${
          block.settings.proxy
        }`;
        if (!block?.settings?.origin) {
          block.settings.origin = block.content.src;
        }
        block.content.src = `${proxyUri}?path=${encodeURI(
          block.settings.origin,
        )}&referer=${proxyUri}`;
      }

      return renderBlock({
        editable: false,
        block,
        display: 'pannel',
        state: [],
      });
    },
  );

  return h('div', { class: 'flex flex-col h-full' }, blocks.value);
};
</script>

<style>
.drawer-left-temp .am-block {
  @apply h-full;
}
.panel-toggle {
  @apply relative p-2 border-1 rounded-r-lg transition-all;
  @apply bg-secondary-75 hover:bg-primary-300 active:bg-primary-200;
}
</style>
