<template>
  <div
    id="drawer-navigation"
    ref="targetEl"
    class="fixed top-0 z-40 transition-all translate-x-0"
    :class="[
      bgColorClass,
      mdAndLarger ? 'relative h-screen overflow-y-auto' : 'h-full',
    ]"
    :dir="rtl ? 'rtl' : 'ltr'"
    tabindex="-1"
    aria-label="Sidenav">
    <div class="overflow-y-auto py-5 h-full w-full">
      <slot />
    </div>
    <div
      v-if="!mdAndLarger"
      class="p-2 h-fit bg-transparent flex hover:bg-primary-200 cursor-pointer absolute top-0"
      :class="rtl ? '-left-[48px]' : '-right-[48px] scale-x-[-1]'"
      :style="{ 'z-index': 999 }"
      @click="open = !open">
      <div
        class="flex justify-center items-center bg-white border w-8 h-8 rounded-md">
        <Transition name="slide">
          <ChevronRight v-if="show" />
          <Menu v-else />
        </Transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Drawer } from 'flowbite';
import { ref, onMounted, watchEffect } from 'vue';
import { useReveal } from '@/apps/slideshow/useReveal';
import { screens as breakpointsTailwind } from '../../packages/themes/amit-theme';

import { useBreakpoints, useElementSize, useVModel } from '@vueuse/core';
import { ChevronRight, Menu } from 'lucide-vue-next';

import type { DrawerOptions } from 'flowbite';
import type { WritableComputedRef } from 'vue';

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  bgColorClass: {
    type: String,
    default: 'bg-secondary-75',
  },
});
const emit = defineEmits(['open', 'update:show']);
const { rtl } = useReveal();

const open: WritableComputedRef<boolean> = useVModel(props, 'show', emit, {
  eventName: 'update:show',
});

const drawer = ref();
const targetEl = ref<HTMLElement | null>(null);
// @ts-ignore
const { width } = useElementSize(targetEl);

const breakpoints = useBreakpoints(breakpointsTailwind);
const mdAndLarger = breakpoints.greaterOrEqual('md');
const options: DrawerOptions = {
  placement: rtl.value ? 'right' : 'left',
  backdrop: false,
  bodyScrolling: false,
  edge: true,
  edgeOffset: rtl.value ? 'right-[40px]' : 'left-[40px]',
};

onMounted(() => {
  if (targetEl.value) {
    drawer.value = new Drawer(targetEl.value, options);
    if (!mdAndLarger.value) {
      open.value = false;
    }
  }
});

watchEffect(() => {
  if (props.show) {
    drawer.value?.show();
    emit('open');
  } else {
    drawer.value?.hide();
  }
});
</script>
<style>
#drawer-navigation {
  transition: all 0.15s;
}
#drawer-navigation[aria-hidden='true'][dir='rtl'] {
  margin-right: v-bind(`-${width}px`);
}
#drawer-navigation[aria-hidden='true'][dir='ltr'] {
  margin-left: v-bind(`-${width}px`);
}
</style>
