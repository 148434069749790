<template>
  <div :id="id" class="reveal relative">
    <div class="slides w-full h-full scrollbar-hide">
      <template v-for="page in trackSlides" :key="page.id">
        <PageSlide :page="page">
          <template #content>
            <div
              v-if="page.id === context?.getId() && hasReferrerPage"
              class="absolute top-0 inset-x-5 text-primary bg-white font-simplerBold cursor-pointer"
              :style="{ 'z-index': 60 }"
              @click="goToReferencePage">
              {{ getLocalizedText('back_to_exercise') }}
            </div>
          </template>
        </PageSlide>
      </template>
    </div>
    <TeleportTarget
      id="deck-activity"
      temporary
      draggable
      class="absolute z-[100] max-w-4xl"
      style="bottom: 100px"/>
    <slot v-if="$slots.banner" name="banner"></slot>
    <slot name="controls">
      <BasicControls :has-referrer-page="hasReferrerPage" />
    </slot>
    <TeleportTarget id="deck-modal" />
    <AssistantBot v-if="assistants.length" :assistants="assistants" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import PageSlide from './PageSlide.vue';
import { useReveal } from './useReveal';
import TeleportTarget from './teleport/TeleportTarget.vue';
import AssistantBot from '@/components/ChatBot/AssistantBot.vue';
import BasicControls from '@/apps/slideshow/BasicControls.vue';
import { useEventListener } from '@vueuse/core';

defineProps<{
  id: string;
}>();

const {
  getLocalizedText,
  trackSlides,
  context,
  onEmit,
  toPage,
  onNavigationChange,
  slideshow,
  recalculateSlide,
} = useReveal();
const referrerPage = ref();
const hasReferrerPage = computed(() => !!referrerPage.value);

const assistants = computed(
  () => slideshow.value?.addons?.filter(a => a.type === 'assistant') ?? [],
);

useEventListener('fullscreenchange', async () => {
  if (!document.fullscreenElement) {
    await recalculateSlide(); // Ensure this is defined and accessible
  }
});

onEmit(({ event }) => {
  if (event.name === 'link:jump-page') {
    referrerPage.value = context.value.getId();
    toPage(event.page);
  }
});

const goToReferencePage = () => {
  toPage(referrerPage.value);
  referrerPage.value = null;
};
onNavigationChange(() => {
  referrerPage.value = null;
});
</script>

<style>
@import './reveal.css';

#deck-info > * {
  position: absolute;
}
</style>
