import { computed, onMounted, watch, ref } from 'vue';
import { useSocket } from '@/composables/useSocket';

const SERVER_URL = 'https://amit-bots.com';

interface BotInterface {
  name: string;
  idleVideo: string;
  image: string;
  supportedResponseTypes: [];
}

interface TextInterface {
  text: string;
  chunk: string;
  end: boolean;
}

interface VideoInterface {
  text: string;
  video: string | Blob;
  end: boolean;
}

export function useLoginetConversation(
  updateLastMessage,
  talkVideo,
  talkVideo2,
  videoSpeed,
  responseType,
  openBot,
) {
  const currentBot = ref<BotInterface>();
  const chatId = ref(null);
  const textResponse = ref<TextInterface | null>(null);
  const videoChunkQueue = ref<VideoInterface[] | any>([]);
  const audioChunkQueue = ref<Array<{ voice: ArrayBuffer }>>([]);
  const isVideoPlaying = ref(false);
  const videoTurn = ref<Boolean>(true);
  const sourceBuffer = ref<SourceBuffer | null>(null);
  const mediaSource = ref<MediaSource | null>(null);
  const audioElement = ref<HTMLAudioElement | null>(null);
  const isAudioStarted = ref(false);

  const videoChunkDone = computed(() => videoChunkQueue.value.length === 0);

  const { state, connect, disconnect, on, emit } = useSocket();

  const connectToBot = async botId => {
    try {
      const response = await fetch(`${SERVER_URL}/chats`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ botId }),
      });
      const data = await response.json();
      localStorage.setItem('SELECTED_BOT_KEY', botId);

      currentBot.value = data.bot;
      chatId.value = data.chatId;
      connect(`${SERVER_URL}?chatId=${chatId.value}`);
      initializeSocketListeners();
    } catch (error) {
      console.error('Error connecting to bot:', error);
      updateLastMessage({
        content: 'Sorry, The server is closed. See you later...',
      });
    }
  };

  const fetchBot = async botId => {
    try {
      const response = await fetch(`${SERVER_URL}/bots/${botId}`);
      const data = await response.json();
      return { ...data, id: botId };
    } catch (error) {
      console.error('Error fetching bot:', error);
    }
  };

  const fetchBots = async botIds => {
    try {
      return await Promise.all(botIds.map(fetchBot));
    } catch (error) {
      console.error('Error fetching bots:', error);
      return [];
    }
  };

  const appendMessage = (message: TextInterface) => {
    if (textResponse.value == null) {
      textResponse.value = { text: '', chunk: '', end: false };
    }
    textResponse.value.text += message?.chunk;
    textResponse.value.end = message?.end;
    if (!message?.end) {
      updateLastMessage({ content: textResponse.value.text + ' ...' });
    } else {
      updateLastMessage({ content: textResponse.value.text });
      textResponse.value = null;
    }
  };

  const pushVideo = (videoObj: VideoInterface) => {
    const videoBlob = base64ToBlob(videoObj.video, 'video/mp4');
    if (videoBlob) {
      videoChunkQueue.value.push({
        text: videoObj.text,
        video: videoBlob,
        end: videoObj.end,
      });
      playVideo();
    }
  };

  const appendAudioChunk = voiceObj => {
    audioChunkQueue.value.push(voiceObj);
    appendAudioChunkFromQueue();
  };

  const initializeSocketListeners = () => {
    on('text', message => appendMessage(message));
    on('video', video => pushVideo(video));
    on('voice', voice => appendAudioChunk(voice));
  };

  const disconnectFromBot = () => {
    disconnect();
  };

  watch(
    () => openBot.value,
    () => {
      if (!openBot.value) {
        cleanChunksVideo();
      }
    },
  );

  watch(
    () => videoSpeed.value,
    () => {
      if (audioElement.value) {
        audioElement.value.playbackRate = videoSpeed.value;
      }
    },
  );

  const ask = async options => {
    if (!state.connected) return;
    let { body } = options;
    emit('messages', { message: body, responseType: responseType.value });
  };

  const playVideo = () => {
    if (!videoChunkDone.value) {
      if (!isVideoPlaying.value) {
        isVideoPlaying.value = true;
        const { video } = videoChunkQueue.value.shift();

        const url = URL.createObjectURL(video);
        const currentVideoElement = videoTurn.value ? talkVideo : talkVideo2;
        const nextVideoElement = videoTurn.value ? talkVideo2 : talkVideo;
        playNextVideo(currentVideoElement, nextVideoElement, url);
        videoTurn.value = !videoTurn.value;
      }
    } else {
      if (talkVideo.value) talkVideo.value.style.opacity = '0';
      if (talkVideo2.value) talkVideo2.value.style.opacity = '0';
    }
  };

  const cleanChunksVideo = () => {
    videoChunkQueue.value = [];
    isVideoPlaying.value = false;
    if (audioElement.value) audioElement.value.muted = true;
    if (talkVideo.value) talkVideo.value.style.opacity = '0';
    if (talkVideo2.value) talkVideo2.value.style.opacity = '0';
  };

  const playNextVideo = (currentVideoElement, nextVideoElement, url) => {
    if (!nextVideoElement.value) return;
    nextVideoElement.value.src = url;
    nextVideoElement.value.style.opacity = '1';
    nextVideoElement.value.playbackRate = videoSpeed.value;
    currentVideoElement.value.style.opacity = '0';

    nextVideoElement.value.onended = () => {
      isVideoPlaying.value = false;
      playVideo();
      URL.revokeObjectURL(url);
    };
  };

  const appendAudioChunkFromQueue = () => {
    if (
      sourceBuffer.value &&
      !sourceBuffer.value.updating &&
      audioChunkQueue.value.length > 0
    ) {
      const { voice } = audioChunkQueue.value.shift()!;
      sourceBuffer.value.appendBuffer(new Uint8Array(voice));
      playAudio();
    }
  };

  const playAudio = () => {
    if (audioElement.value && !isAudioStarted.value) {
      audioElement.value.play();
      isAudioStarted.value = true;
    }
  };

  const initializeMediaSource = () => {
    mediaSource.value = new MediaSource();
    const audioUrl = URL.createObjectURL(mediaSource.value);
    audioElement.value = new Audio(audioUrl);
    mediaSource.value.addEventListener('sourceopen', () => {
      if (mediaSource.value) {
        sourceBuffer.value = mediaSource.value.addSourceBuffer('audio/mpeg');
        sourceBuffer.value.addEventListener(
          'updateend',
          appendAudioChunkFromQueue,
        );
        sourceBuffer.value.addEventListener('error', () => {
          console.error('SourceBuffer encountered an error');
        });
      }
    });
  };

  onMounted(() => {
    initializeMediaSource();
  });

  const base64ToBlob = (base64, mimeType) => {
    if (!base64) return null;
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length)
      .fill(null)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  };

  return {
    ask,
    currentBot,
    connectToBot,
    fetchBots,
    disconnectFromBot,
    cleanChunksVideo,
    audioElement,
  };
}
