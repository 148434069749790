<template>
  <section
    ref="slide"
    :aria-hidden="!slideVisible"
    :data-page="page.id"
    :data-background-color="page.display.backgroundColor"
    :data-background-image="page.display.backgroundImage"
    :data-background-size="page.display.backgroundSize"
    :data-background-repeat="page.display.backgroundRepeat"
    :data-background-opacity="page.display.backgroundOpacity"
    :data-background-video="page.display.backgroundVideo"
    :data-transition="page.display.transition"
    :data-transition-speed="page.display.transitionSpeed"
    :data-background-transition="page.display.backgroundTransition"
    class="text-start h-full slide">
    <slot name="content"></slot>
    <div
      class="r-stack h-full"
      :class="{ 'overflow-y-auto': !page.fragments?.length }">
      <PageContent />
      <template v-for="(fragment, index) in page.fragments" :key="index">
        <div class="fragment fade-in-then-out" :data-fragment-index="index">
          <FeedbackMessage
            :completion="fragment.completion"
            :message="fragment.message"/>
        </div>
      </template>
    </div>
    <template v-for="element in page.elements" :key="element.src">
      <img :src="element.src" :class="element.classes" alt="" />
    </template>
  </section>
  <SafeTeleport v-if="currentModalBlock" to="#deck-modal">
    <BaseModal
      :show="!!currentModalBlock"
      scoped
      size="3xl"
      @close="closeModal">
      <template #body>
        <ModalContent :key="currentModalBlock?.id" />
      </template>
      <template #footer>
        <div class="flex justify-end">
          <BaseButton
            :disabled="modalState.disabled || modalState.submitting"
            :loading="modalState.submitting"
            @click="handleSubmission">
            <span class="text-white text-sm">
              {{ getLocalizedText(modalState.submitted ? 'close' : 'send') }}
            </span>
          </BaseButton>
        </div>
      </template>
    </BaseModal>
  </SafeTeleport>
  <PVSidebar
    v-model:visible="visibleHints"
    :style="{
      width: '100%',
    }"
    :dismissable="false"
    :position="locale === 'en' ? 'right' : 'left'"
    :modal="false"
    :header="locale === 'en' ? 'HINT' : 'רמז'"
    :pt="preset"
    :pt-options="{ mergeSections: false, mergeProps: true }"
    :dir="locale === 'en' ? 'ltr' : 'rtl'"
    class="preview-wrapper">
    <div class="flex flex-col p-5 gap-2">
      <HintContent />
    </div>
  </PVSidebar>
</template>

<script setup lang="ts">
import { h, ref, computed, watchEffect, provide } from 'vue';
import { useArrayMap } from '@vueuse/core';
import { Engine } from 'json-rules-engine';
import { renderBlock } from './useBlockMapper';
import { useElementVisibility } from '@vueuse/core';
import { FeedbackMessage, BaseModal } from '@/components/index';
import { useInteractions, useReveal } from '@/apps/slideshow/useReveal';
import BaseButton from '@/components/Button/BaseButton.vue';
import SafeTeleport from './teleport/SafeTeleport.vue';
import { range } from 'lodash';
import type { BlockState } from '@/apps/slideshow/blocks';
import type { PageSlide } from '@/apps/slideshow/types';
import PVSidebar from 'primevue/sidebar';

const props = defineProps<{
  page: PageSlide;
}>();

const { onSubmit, onAction, onPause, onVisibilityChange } = useInteractions();
const { onEmit, context, getLocalizedText, onNext, onSlide, locale } =
  useReveal();
const visibleHints = ref(false);
const currentHints = ref();
const scope = computed<string>(() => {
  if (props.page.fragments.length) {
    return `${props.page.id}_fragment`;
  }
  return props.page.id;
});

provide('scope', scope);

onNext(() => {
  visibleHints.value = false;
});

onSlide(() => {
  visibleHints.value = false;
});

const preset = {
  mask: {
    class: '!z-[9000]',
    style: {
      width: '33%',
      height: 'calc(100% - 55px)',
      ...(locale.value === 'en' ? { right: '0', left: 'auto' } : {}),
    },
  },
};

const slide = ref(null);
const currentModalBlock = ref();
const modalState = computed<BlockState>(
  () => currentModalBlock.value.state || {},
);

const modalBlocks = computed(() => {
  return props.page.blocks.filter(block => block.settings?.display === 'modal');
});

const pageBlocks = computed(() =>
  props.page.blocks.filter(
    block => !block.settings?.display || block.settings.display === 'default',
  ),
);
const isEventInSeenEvents = (event, seenEvents) =>
  range(event.seconds - 4, event.seconds + 5).some(i =>
    seenEvents.has(`${event.name}/${event.source}/${i}`),
  );

const slideVisible = useElementVisibility(slide);

const hintBlocks = computed(() =>
  props.page.blocks.filter(block => currentHints.value.includes(block.id)),
);

watchEffect(() => {
  onVisibilityChange.trigger({
    page: props.page.id,
    visible: slideVisible.value,
  });
});

const seenEvents = new Set();
// FIXME: why this gets triggered 12 times?
onEmit(({ event }) => {
  const currentBlock = pageBlocks.value.find(block => block.id == event.source);
  if (!currentBlock) return;
  if (event.name === 'show-hint') {
    visibleHints.value = true;
    currentHints.value = event.hints;
  }
  if (!event.force && isEventInSeenEvents(event, seenEvents)) {
    return;
  }
  if (event.name == 'word_cloud:update') {
    onSubmit.trigger({
      block: currentBlock,
      context: context.value,
    });
  }

  const { rules = [] } = props.page;

  const engine = new Engine(rules.filter(rule => rule.trigger === event.name));
  engine.run({
    params: {
      ...event,
    },
  });

  engine.on('show-modal', ({ block_id, unique }: any) => {
    if (currentModalBlock.value) {
      onPause.trigger({});
      return;
    }

    currentModalBlock.value = modalBlocks.value.find(
      block => block.id === block_id,
    );

    if (currentModalBlock.value) {
      onAction.trigger({
        context: context.value,
        event: { block: currentModalBlock.value },
        disableExercise: true,
      });
      onPause.trigger({});

      if (unique) {
        seenEvents.add(`${event.name}/${event.source}/${event?.seconds}`);
      }
    }
  });
});

const HintContent = () => {
  const PageBlocks = useArrayMap(hintBlocks.value, (block: any) =>
    renderBlock({
      editable: false,
      display: 'pannel',
      locale: locale.value,
      block,
    }),
  );
  return h(
    'div',
    {
      class:
        'flex flex-col space-y-3 text-start pb-20 overflow-y-auto h-full w-full page-content',
    },
    PageBlocks.value,
  );
};

const closeModal = () => {
  currentModalBlock.value = null;
};

const modalSubmission = computed(() => {
  const records = modalState.value.submission?.records ?? [];
  return (
    records.find(record => record.question === currentModalBlock.value.id) ?? {}
  );
});
//FIXME - this modal is always enabled, even if there is no answer
const ModalContent = () => {
  if (modalState.value.submitted) {
    const { feedback: message, completion } = modalSubmission.value;
    return h(FeedbackMessage, {
      completion,
      message,
    });
  }
console.log(currentModalBlock.value)
  const content = renderBlock({
    editable: false,
    block: currentModalBlock.value,
    state: props.page.state?.[currentModalBlock.value.id] ?? ((currentModalBlock.value.name == 'free_text') ? '' : []),
    preview: true,
  });

  return h('div', content);
};

const getBlock = (block, children = {}) => {
  const state =
    block?.state?.historyState ?? props.page.state?.[block.id] ?? [];
  return renderBlock({
    editable: false,
    block,
    locale: locale.value,
    state,
    children,
    preview: true,
  });
};

const PageContent = () => {
  const blocks = useArrayMap(pageBlocks.value, block => {
    let children: Record<string, any> = {};
    if (block.name === 'two_columns') {
      children.left = () => block.content.left.map(block => getBlock(block));
      children.right = () => block.content.right.map(block => getBlock(block));
    }

    return getBlock(block, children);
  });
  const blocksName = useArrayMap(props.page.blocks, block => block.name);

  const centered = computed(() => true);

  return h(
    'div',
    {
      class: `items-start flex flex-col gap-5 text-start px-5 pb-20 pt-5 overflow-y-auto no-scrollbar h-fit w-full relative z-50 ${
        props.page.fragments.length && 'fragment fade-out'
      } ${blocksName.value.join(' ')} ${centered.value && 'centered'}`,
      'data-fragment-index': 0,
    },
    blocks.value,
  );
};

const handleSubmission = () => {
  if (!currentModalBlock.value.state.submitted) {
    currentModalBlock.value.state.submitting = true;
    onSubmit.trigger({
      block: currentModalBlock.value,
      context: context.value,
    });
  } else {
    currentModalBlock.value = null;
  }
};
</script>

<style scoped>
.r-stack > *:not(.centered) {
  margin: 0 !important;
}

.r-stack > *:not(.two_columns) {
  @apply max-w-5xl;
}
</style>
