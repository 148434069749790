import { parseISO, formatDistanceToNow, differenceInMinutes } from 'date-fns';
import { ref } from 'vue';
import { useIntervalFn } from '@vueuse/core';

import type { Ref } from 'vue';

import type { Locale } from 'date-fns';

export type TimeAgoOptions = {
  includeSeconds?: boolean;
  addSuffix?: boolean;
  autoUpdate?: boolean;
  locale?: Locale;
  interval?: number;
};

export function useTimeAgo(
  options: TimeAgoOptions = {},
): (date: string | Date) => Ref<string> {
  const getTimeAgo = (date: string | Date): string => {
    if (typeof date === 'string') {
      date = parseISO(date);
    }

    if (differenceInMinutes(new Date(), date) <= 1) {
      return '';
    }

    return formatDistanceToNow(date, {
      includeSeconds: options.includeSeconds ?? false,
      addSuffix: options.addSuffix ?? true,
      locale: options.locale,
    });
  };

  return (date: string | Date): Ref<string> => {
    const timeago = ref(getTimeAgo(date));

    if (options.autoUpdate) {
      const interval = options.interval ?? 60 * 1000;
      useIntervalFn(() => {
        timeago.value = getTimeAgo(date);
      }, interval);
    }

    return timeago;
  };
}
