import { reactive, ref } from 'vue';
import { io } from 'socket.io-client';

export function useSocket() {
  const state = reactive({
    connected: false,
    events: [] as any,
  });

  const socket = ref(null as any);

  const connect = url => {
    socket.value = io(url);

    socket.value.on('connect', () => {
      state.connected = true;
    });

    socket.value.on('disconnect', () => {
      state.connected = false;
    });
  };

  const disconnect = () => {
    if (socket.value) {
      socket.value.disconnect();
    }
  };

  const on = (event, handler) => {
    if (socket.value) {
      socket.value.on(event, handler);
    }
  };

  const emit = (event, data) => {
    if (socket.value) {
      socket.value.emit(event, data);
    }
  };

  return {
    state,
    socket,
    connect,
    disconnect,
    on,
    emit,
  };
}
